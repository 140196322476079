




























































































import Vue from 'vue'
import { mapActions } from 'vuex'
import { ApiResponse } from '@/api'
import uiState from '@/ui-state'

export default Vue.extend({
  data() {
    return {
      uiState: uiState([
        'initial',
        'loading',
        'success',
        'login_failed',
        'error',
        'session-expired'
      ]),
      form: {
        email: '',
        password: ''
      }
    }
  },

  computed: {
    showLoginFailedError(): boolean {
      return this.uiState.is('login_failed')
    },

    hasError(): boolean {
      return this.uiState.is('error')
    },

    isLoading(): boolean {
      return this.uiState.is('loading')
    },

    sessionExpired(): boolean {
      return this.uiState.is('session-expired')
    }
  },

  created() {
    if (
      this.$route.query.reason &&
      this.$route.query.reason === 'session-expired'
    ) {
      this.uiState.set('session-expired')
    }
  },

  methods: {
    ...mapActions('auth', ['login']),

    async onSubmit() {
      this.uiState.set('loading')
      const response: ApiResponse = await this.login(this.form)

      if (response.isSuccessful) {
        this.uiState.set('success')
        this.$router.push({
          name: '2fa',
          query: { redirect: this.$route.query.redirect }
        })
      } else if (response.status === 401) {
        this.uiState.set('login_failed')
      } else {
        this.uiState.set('error')
      }
    }
  }
})
